import { useAppContext } from 'contexts/AppContext';
import { useUserSyncContext } from 'contexts/UserSyncContext';

const FailedJobsBanner = () => {
  const { appTheme } = useAppContext();
  const { failedJobs } = useUserSyncContext();

  if (!failedJobs) return null;

  return (
    <div
      style={{
        flexGrow: 1,
        width: '100%',
        borderRadius: 4,
        border: `1px solid ${appTheme?.siteVariables?.colors?.grey?.[200]}`,
        backgroundColor: appTheme?.siteVariables?.white,
        padding: 10,
        marginBottom: 16,
        marginTop: 16,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ marginLeft: 8 }}>
          <p style={{ margin: 0 }}>
            There are currently <strong>{failedJobs} failed jobs</strong> that need to be resolved.
            You can sync users again using the actions dropdown and selecting "Resync users".
          </p>
        </div>
      </div>
    </div>
  );
};

export default FailedJobsBanner;
