import { Loader } from '@fluentui/react-northstar';
import ProgressBar from 'components/common/ProgressBar';
import { useAppContext } from 'contexts/AppContext';
import { useUserSyncContext } from 'contexts/UserSyncContext';

const UserSyncBanner = () => {
  const { appTheme } = useAppContext();
  const {
    showSyncBanner,
    progress,
    completed,
    totalUsers,
    syncedUsers,
    hasRunningJobs,
  } = useUserSyncContext();

  if (!showSyncBanner) return null;

  return (
    <div
      style={{
        flexGrow: 1,
        width: '100%',
        borderRadius: 4,
        border: `1px solid ${appTheme?.siteVariables?.colors?.grey?.[200]}`,
        backgroundColor: appTheme?.siteVariables?.white,
        padding: 10,
        marginBottom: 16,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Loader size="smallest" />
        <div style={{ marginLeft: 8 }}>
          <p style={{ margin: 0 }}>
            <strong>Syncing users:</strong> Please wait while we sync the users in the background.
            You may click the Refresh button below to see the updated list of users.
            Please do not close or refresh the page.
          </p>
          {syncedUsers < totalUsers && (
            <p style={{ margin: 0 }}>
              <span>{syncedUsers} / {totalUsers} number of users processed.</span>
            </p>
          )}
        </div>
      </div>
      {(progress > 0 || !completed) && (
        <div style={{ marginTop: 16, width: '100%' }}>
          <ProgressBar
            bgcolor={appTheme?.siteVariables?.colors?.green?.[300]}
            color="white"
            progress={progress}
            height={8}
            hideLabel
          />
        </div>
      )}
    </div>
  );
};

export default UserSyncBanner;
