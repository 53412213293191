import { useEffect, useRef, useState } from 'react';
import { getFailedUserSyncJobs, reprocessJobs } from 'redux/services/userSyncJobApi';
import { UserJobSync } from 'types/userJob';
import { useMutation } from './useMutation';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useQuery } from './useQuery';

const useFailedUserSyncJobs = (onSuccess?: () => void | Promise<void>) => {
  const [jobs, setJobs] = useState<UserJobSync[]>([]);
  const interval = useRef<number | undefined>();
  const token = useSelector((state: RootState) => state.auth.token);

  const { data, refetch: fetchFailedJobs } = useQuery(() => getFailedUserSyncJobs());

  const { mutate, loading } = useMutation({
    callback: async (ids: string[]) => reprocessJobs(ids),
    onSuccess: (res) => {
      if (res) setJobs([]);
      fetchFailedJobs();
      if (onSuccess) onSuccess();
    },
  });

  const reprocessFailedJobs = () => {
    mutate(jobs.map((job) => job.id));
  };

  useEffect(() => {
    if (data?.length > 0) {
      setJobs(data);
    } else {
      setJobs([]);
    }
  }, [data]);

  useEffect(() => {
    if (interval.current != null) return;

    fetchFailedJobs();
    interval.current = window.setInterval(async () => {
      await fetchFailedJobs();
    }, 10000);
  }, [token]);

  return {
    jobs,
    isReprocessingFailedJobs: loading ?? false,
    reprocessFailedJobs,
    refetch: fetchFailedJobs,
  };
};

export default useFailedUserSyncJobs;
