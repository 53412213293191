/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeatures } from 'redux/services/featuresApi';
import { RootState } from 'redux/store';
import { Feature } from 'types/tenants';

type FeaturesProps = {
  features: Feature[];
};

const FeaturesContext = React.createContext<FeaturesProps>({
  features: [],
});

const useFeatures = () => {
  const context = useContext(FeaturesContext);
  if (context === undefined) {
    throw new Error('useFeatures must be used within a FeaturesContextProvider');
  }
  return context;
};

const FeaturesContextProvider: React.FC = ({ children }) => {
  const { token } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState<Feature[]>([]);

  const getTenantFeatures = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const features = await getFeatures(token);
      setFeatures(features);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token) return;
    getTenantFeatures();
  }, [token]);

  if (loading) return null;

  return <FeaturesContext.Provider value={{ features }}>{children}</FeaturesContext.Provider>;
};

export { FeaturesContext, FeaturesContextProvider, useFeatures };
