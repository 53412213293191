import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { startSyncUsers, getUserSyncJobsByIds } from 'redux/services/userSyncJobApi';
import { RootState } from 'redux/store';
import { JobUserSyncStatus, TenantJobsResponse } from 'types/userJob';
import { useMutation } from './useMutation';

const RETRY_INTERVAL = 5000;

const useRunningJobs = () => {
  const [enabled, setEnabled] = useState(false);
  const interval = useRef<number | undefined>();
  const [totalUsers, setTotalUsers] = useState(0);
  const [syncedUsers, setSyncedUsers] = useState(0);
  const [progress, setProgress] = useState(0);
  const runningJobs = useRef<string[]>([]);
  const [isProcessingJobs, setIsProcessingJobs] = useState(false);
  const [runningJobsProgress, setRunningJobsProgress] = useState<TenantJobsResponse>();
  const token = useSelector((state: RootState) => state.auth.token);

  const fetchUserSyncJobs = async () => {
    if (!runningJobs.current.length) return;

    setIsProcessingJobs(true);
    try {
      const res = await getUserSyncJobsByIds(runningJobs.current);
      if (!res) return;
      setRunningJobsProgress(res);
    } catch (_) {
      console.error(_);
    } finally {
      setIsProcessingJobs(false);
    }
  };

  const { mutate, loading: isStartSyncLoading } = useMutation<void, string[]>({
    callback: async (): Promise<string[]> => {
      return startSyncUsers();
    },
    onSuccess: (res) => {
      runningJobs.current = res;
    },
  });

  const startSync = async () => {
    if (isProcessingJobs) return;
    mutate();
  };

  const initiateRunningJobs = (jobs: string[]) => {
    setEnabled(true);
    runningJobs.current = jobs;
    fetchUserSyncJobs();
  };

  const reset = () => {
    setProgress(0);
    setTotalUsers(0);
    setSyncedUsers(0);
    setEnabled(false);
    clearInterval(interval.current);
    interval.current = undefined;
  };

  useEffect(() => {
    if (!enabled) return;
    if (interval.current && interval.current > 0) return;

    fetchUserSyncJobs();
    interval.current = window.setInterval(async () => {
      await fetchUserSyncJobs();
    }, RETRY_INTERVAL);
  }, [token, enabled]);

  useEffect(() => {
    if (!runningJobsProgress) return;

    const resJobs = runningJobsProgress?.jobs ?? [];

    setSyncedUsers(runningJobsProgress?.syncedUsers ?? 0);
    if (!totalUsers) {
      setTotalUsers(runningJobsProgress?.totalUsers ?? 0);
    }

    const pendingJobsCount = resJobs?.filter((job) => job.status === JobUserSyncStatus.Pending).length ?? 0;
    const progressPercentage = ((resJobs.length - pendingJobsCount) / resJobs.length) * 100;
    setProgress(parseInt(progressPercentage.toString(), 10));

    if (progressPercentage >= 100) {
      reset();
    }
  }, [runningJobsProgress]);

  const hasRunningJobs = runningJobs.current.length > 0;
  const completed = enabled && progress === 100;

  return {
    enabled,
    hasRunningJobs,
    completed,
    progress,
    loading: isStartSyncLoading || isProcessingJobs,
    totalUsers,
    syncedUsers,
    initiateRunningJobs,
    startSync,
  };
};

export default useRunningJobs;
