/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BulletsIcon,
  ChevronDownIcon,
  DownloadIcon,
  FilesUploadIcon,
  RedoIcon,
  RetryIcon,
  SettingsIcon,
  TranscriptIcon,
} from '@fluentui/react-icons-northstar';
import { Alert, Button, CallIcon, Dialog, Form, Loader, Popup } from '@fluentui/react-northstar';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AppContext } from 'contexts/AppContext';
import { useFeatures } from 'contexts/FeaturesContext';
import { useUserSyncContext } from 'contexts/UserSyncContext';
import { useDownloadTenantData } from 'hooks/useDownloadTenantData';
import { useMutation } from 'hooks/useMutation';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { generateReport } from 'redux/services/exportApi';
import { resetTenant } from 'redux/services/tenantsApi';
import { RootState } from 'redux/store';
import { User } from 'types';
import { baseUrl } from '../../../../authConfig';
import { getHeaders } from '../../../../helpers/utils';
import { useInterval } from '../../../../hooks/useInterval';
import { useGetUpdateUserStatusMutation } from '../../../../redux/services/batchUpdateApi';
import { useImportUsersMutation } from '../../../../redux/services/importApi';
import Context from '../../context';
import { alertStyles, buttonStyles, formStyles, labelStyles } from './ActionPopup.styles';

const SHOW_COMPARE_CONFIG = false;

const ActionPopup = (): React.ReactElement => {
  const {
    filters,
    setFilters,
    isSaving,
    setIsSaving,
    isCompleted,
    setIsCompleted,
    modifiedData,
    setModifiedData,
    resetCache,
    isResettingCache,
    compare,
    isComparing,
  } = useContext(Context) as any;
  const { showAppSettings } = useContext(AppContext);
  const { features } = useFeatures();
  const history = useHistory();
  const { token } = useSelector((state: RootState) => state.auth);
  const [isExporting, setIsExporting] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const { downloadTenantData, isLoading: isDownloadingTenantData } = useDownloadTenantData();
  const {
    startSync,
    reprocessFailedJobs,
    isReprocessingFailedJobs,
    failedJobs,
    showSyncBanner,
    loading,
  } = useUserSyncContext();

  const [importUsers, { data: batchImportData, error: importErrorMsg, reset: resetImportUsers }] =
    useImportUsersMutation();
  const [getUpdateUserStatus, { data: updateUserStatus, reset: resetGetUpdateUserStatus }] =
    useGetUpdateUserStatusMutation();

  const { mutate: resetSetup, loading: isResetLoading } = useMutation<void, { success: boolean }>({
    callback: async (): Promise<{ success: boolean }> => {
      return resetTenant();
    },
    onSuccess: (res: { success: boolean }) => {
      if (!res.success) return;
      history.push('/');
    },
  });

  useEffect(() => {
    if (batchImportData?.id) {
      setModifiedData({
        data: batchImportData.results.map((item) => item.data),
      });
    }
  }, [batchImportData, setModifiedData]);

  useEffect(() => {
    if (importErrorMsg) {
      const getError = importErrorMsg as FetchBaseQueryError;
      setErrorMsg(JSON.stringify(getError?.data));
    }
  }, [importErrorMsg]);

  const updateUserInfo = () => {
    if (updateUserStatus?.results && batchImportData?.id === updateUserStatus?.id) {
      const data = [...modifiedData.data];

      updateUserStatus.results.map((userStatus) => {
        const index = modifiedData.data.findIndex(
          (element: User) => element.userPrincipalName === userStatus.data.userPrincipalName,
        );

        data[index] = {
          ...data[index],
          errors: userStatus.errors,
          status: userStatus.status,
        };

        setModifiedData({ data });
        return true;
      });
    }
  };

  useInterval(() => {
    if (isCompleted || errorMsg) return;
    const { id: jobId } = batchImportData || {};
    const isEqualId = jobId === updateUserStatus?.id;
    if (jobId && (!updateUserStatus || updateUserStatus.status === 0 || !isEqualId)) {
      setIsSaving(true);
      setIsOpen(false);
      getUpdateUserStatus({ jobId, token }).unwrap();
    } else {
      if (updateUserStatus && updateUserStatus && isEqualId) {
        resetState();
        setIsCompleted(true);
        setIsSaving(false);
      }
    }
    updateUserInfo();
  }, 3000);

  const exportUsers = async () => {
    if (!token) return;
    setIsExporting(true);
    // TODO: move to RTK
    try {
      const response = await fetch(`${baseUrl}powershell/exportUsers`, { headers: getHeaders(token) });
      if (!response.ok) throw new Error('Failed to export data');
      const data = await response.blob();
      const anchor = document.createElement('a');
      const url = window.URL.createObjectURL(data);
      document.body.appendChild(anchor);
      anchor.href = url;
      anchor.download = 'symbioUsers.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  const buttonProps = {
    loader: 'Program card',
    text: true,
  };

  const dialogProps = {
    cancelButton: 'Cancel',
    confirmButton: 'Import',
  };

  const onConfirm = async () => {
    setIsOpen(true);
    setErrorMsg('');

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      await importUsers({ file: formData, token }).unwrap();

      if (!errorMsg) {
        setIsCompleted(false);
        setIsSaving(true);
        setFilters({
          ...filters,
          status: {
            ...filters.status,
            unmodified: false,
          },
        });
        setIsOpen(false);
      }
    } else {
      setErrorMsg('Please upload file.');
    }
  };

  const onCancel = () => setIsOpen(false);

  const resetState = () => {
    // importUsers({ file: [], token: null }).unwrap();
    // getUpdateUserStatus({ jobId: 0, token: null }).unwrap();
    resetImportUsers();
    resetGetUpdateUserStatus();
  };

  const onGenerateReport = async (reportType: 'user_update' | 'user_count' = 'user_update') => {
    if (!token || isGeneratingReport) return;
    setIsGeneratingReport(true);
    // TODO: move to RTK
    try {
      const hours = Math.floor(Math.abs(Date.now() - new Date('2020-01-01T00:00:00.000Z').getTime()) / 3600000);
      const result = await generateReport(token, hours, reportType);
      if (!result) return;
      const anchor = document.createElement('a');
      const url = window.URL.createObjectURL(result);
      document.body.appendChild(anchor);
      anchor.href = url;
      const urlPaths = url.split('/');
      const fileName = urlPaths[urlPaths.length - 1];
      anchor.download = fileName;
      anchor.click();
    } catch (error) {
      console.error(error);
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const isResyncBusy = isReprocessingFailedJobs || loading || showSyncBanner;

  return (
    <Popup
      position="below"
      content={
        <Form style={formStyles}>
          <Dialog
            {...dialogProps}
            open={isOpen}
            content={
              <>
                {errorMsg && <Alert danger content={`Error: ${errorMsg}`} style={alertStyles} />}
                <p style={labelStyles}>Select file: *</p>
                <input
                  type="file"
                  onChange={(e: React.SyntheticEvent<HTMLInputElement, Event>) => {
                    if (!e?.currentTarget?.files?.[0]) return;
                    setSelectedFile(e.currentTarget.files[0]);
                  }}
                />
              </>
            }
            onConfirm={onConfirm}
            onCancel={onCancel}
            header="Import Users"
            trigger={
              <Button
                {...buttonProps}
                content="Import Users"
                disabled={isSaving}
                icon={<FilesUploadIcon />}
                style={buttonStyles}
                onClick={() => setIsOpen(true)}
              />
            }
          />
          <Button
            {...buttonProps}
            data-testid="btn-export-users"
            content="Export Users"
            disabled={isExporting}
            icon={isExporting ? <Loader size="smallest" /> : <DownloadIcon />}
            onClick={exportUsers}
            style={buttonStyles}
          />
          <Button
            {...buttonProps}
            data-testid="btn-download-tenant-data"
            content="Download logs"
            disabled={isDownloadingTenantData}
            icon={isDownloadingTenantData ? <Loader size="smallest" /> : <DownloadIcon />}
            onClick={() => downloadTenantData()}
            style={buttonStyles}
          />
          <Button
            {...buttonProps}
            data-testid="btn-reset-cache"
            content="Reset Cache"
            disabled={isResettingCache || modifiedData?.data?.filter((i: User) => i.status === 0).length > 0}
            icon={isResettingCache ? <Loader size="smallest" /> : <RetryIcon />}
            onClick={resetCache}
            style={buttonStyles}
          />
          {features.includes('ResetAllowed') && (
            <Button
              {...buttonProps}
              data-testid="btn-reset-tenant"
              className="btn-reset-tenant"
              disabled={isResetLoading}
              content="Reset Tenant"
              icon={isResetLoading ? <Loader size="smallest" /> : <RedoIcon />}
              onClick={() => resetSetup()}
              style={buttonStyles}
            />
          )}
          <Button
            {...buttonProps}
            data-testid="btn-manage-cli"
            className="btn-manage-cli"
            content="Manage CLI"
            icon={<CallIcon />}
            onClick={() => history.push('/callerId')}
            style={buttonStyles}
          />
          {SHOW_COMPARE_CONFIG && (
            <Button
              {...buttonProps}
              data-testid="btn-compare"
              className="btn-compare"
              content="Compare"
              disabled={isComparing}
              icon={isComparing ? <Loader size="smallest" /> : <BulletsIcon />}
              onClick={compare}
              style={buttonStyles}
            />
          )}
          {features.includes('ExportAuditAllowed') && (
            <Button
              {...buttonProps}
              data-testid="btn-report-audit-cli"
              className="btn-report-audit-cli"
              content="Export Audit Report"
              icon={<TranscriptIcon />}
              disabled={isGeneratingReport}
              onClick={() => onGenerateReport('user_update')}
              style={buttonStyles}
            />
          )}
          <Button
            {...buttonProps}
            data-testid="btn-settings-cli"
            className="btn-settings-cli"
            content="Add Region(s)"
            icon={<SettingsIcon />}
            onClick={() => history.push('/setting')}
            style={buttonStyles}
          />
          {showAppSettings && (
            <Button
              {...buttonProps}
              data-testid="btn-app-settings"
              className="btn-app-settings"
              content="Settings"
              icon={<SettingsIcon />}
              onClick={() => history.push('/app-settings')}
              style={buttonStyles}
            />
          )}
          {features.includes('LargeUserSyncBackgroundAllowed') && (
            <>
              {failedJobs > 0 ? (
                <Button
                  {...buttonProps}
                  data-testid="btn-resync-users"
                  content="Resync Users"
                  disabled={isResyncBusy}
                  onClick={() => reprocessFailedJobs()}
                  icon={isResyncBusy ? <Loader size="smallest" /> : <RetryIcon />}
                  style={buttonStyles}
                />
              ) : (
                <Button
                  {...buttonProps}
                  data-testid="btn-start-sync"
                  content="Resync Users"
                  disabled={isResyncBusy}
                  onClick={() => startSync()}
                  icon={isResyncBusy ? <Loader size="smallest" /> : <RedoIcon />}
                  style={buttonStyles}
                />
              )}
            </>
          )}
        </Form>
      }
      trapFocus
      trigger={
        <Button
          content="More Actions"
          icon={<ChevronDownIcon />}
          iconPosition="after"
          primary
          data-testid="btn-more-actions"
          className="btn-more-actions"
        />
      }
    />
  );
};

export default ActionPopup;
