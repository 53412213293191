import { useEffect, useRef, useState } from 'react';
import { getPendingUserSyncJobs, reprocessJobs } from 'redux/services/userSyncJobApi';
import { UserJobSync } from 'types/userJob';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useQuery } from './useQuery';

const usePendingUserSyncJobs = (onSuccess?: () => void | Promise<void>) => {
  const [jobs, setJobs] = useState<UserJobSync[]>([]);
  const interval = useRef<number | undefined>();
  const token = useSelector((state: RootState) => state.auth.token);

  const { data, refetch: fetchJobs } = useQuery(() => getPendingUserSyncJobs());

  useEffect(() => {
    if (data?.length > 0) {
      setJobs(data);
    } else {
      setJobs([]);
    }
  }, [data]);

  useEffect(() => {
    if (interval.current != null) return;

    fetchJobs();
    interval.current = window.setInterval(async () => {
      await fetchJobs();
    }, 10000);
  }, [token]);

  return {
    jobs,
    refetch: fetchJobs,
  };
};

export default usePendingUserSyncJobs;
