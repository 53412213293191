/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import useFailedUserSyncJobs from 'hooks/useFailedUserSyncJobs';
import usePendingUserSyncJobs from 'hooks/usePendingUserSyncJobs';
import useRunningJobs from 'hooks/useRunningJobs';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';

type UserSyncState = {
  showSyncBanner: boolean;
  completed: boolean;
  progress: number;
  loading?: boolean;
  totalUsers: number;
  syncedUsers: number;
  failedJobs: number;
  isReprocessingFailedJobs: boolean;
  hasRunningJobs: boolean;
  reprocessFailedJobs: () => void;
  startSync: () => void;
};

type UserSyncContextProps = {};

const UserSyncContext = createContext<UserSyncState>({
  showSyncBanner: false,
  completed: false,
  progress: 0,
  loading: false,
  totalUsers: 0,
  syncedUsers: 0,
  failedJobs: 0,
  hasRunningJobs: false,
  isReprocessingFailedJobs: false,
  reprocessFailedJobs: () => { },
  startSync: () => { },
});

const UserSyncProvider = ({ children }: PropsWithChildren<UserSyncContextProps>) => {
  const {
    jobs: pendingJobs,
    refetch,
  } = usePendingUserSyncJobs();

  const {
    jobs: allFailedJobs,
    isReprocessingFailedJobs,
    reprocessFailedJobs,
  } = useFailedUserSyncJobs(refetch);

  const {
    enabled,
    completed,
    progress,
    loading,
    totalUsers,
    syncedUsers,
    hasRunningJobs,
    startSync,
    initiateRunningJobs,
  } = useRunningJobs();

  useEffect(() => {
    if (enabled) return;
    if (pendingJobs.length > 0) {
      initiateRunningJobs(pendingJobs.map((job) => job.id));
    }
  }, [enabled, pendingJobs]);

  const failedJobs = allFailedJobs?.length ?? 0;

  return (
    <UserSyncContext.Provider
      value={{
        showSyncBanner: enabled,
        completed,
        progress,
        loading,
        totalUsers,
        syncedUsers,
        failedJobs,
        hasRunningJobs,
        isReprocessingFailedJobs,
        reprocessFailedJobs,
        startSync,
      }}
    >
      {children}
    </UserSyncContext.Provider>
  );
};

const useUserSyncContext = () => useContext(UserSyncContext);

export { UserSyncContext, UserSyncProvider, useUserSyncContext };
